<template>
  <div :class="{ projects: true, 'is-close-messages': !getIsOpen }">
    <q-page-container>
      <q-page>
        <div class="column" style="height: calc(100vh - 86px)">
          <div>
            <TableProjects />
          </div>
          <div>
            <TableProjects title="Tous les projets" :is-all-projects="true" />
          </div>
        </div>
      </q-page>
    </q-page-container>

    <Messages />
  </div>
</template>

<script>
import TableProjects from '@/components/projects/TableProjects.vue'
import Messages from '@/components/global/Messages.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MainProjects',
  components: {
    Messages,
    TableProjects
  },
  computed: {
    ...mapGetters('messages', ['getIsOpen'])
  }
}
</script>
