var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-projects"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"action"},[_c('q-btn',{staticClass:"btn-new",attrs:{"icon-right":"add","label":"Nouveau projet"},on:{"click":function($event){return _vm.$router.push('/new-project')}}})],1)]),_c('div',{staticClass:"bottom"},[(
        (_vm.isAllProjects && _vm.getAllProjects.length) ||
          (!_vm.isAllProjects && _vm.getLastProjects.length)
      )?_c('div',{staticClass:"row"},[_c('q-table',{attrs:{"data":_vm.isAllProjects
            ? _vm.isSearch
              ? _vm.searchAll
              : _vm.getAllProjects
            : _vm.isSearch
            ? _vm.searchLast
            : _vm.getLastProjects,"columns":_vm.columns,"hide-bottom":true,"pagination":_vm.pagination,"sort-method":_vm.customSort},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props},on:{"click":function($event){return _vm.$router.push({
                name: 'Project',
                params: { projectId: props.row.projectId }
              })}}},[_c('q-td',{key:"name",staticClass:"related-td td-project-name",attrs:{"props":props}},[_c('span',{staticClass:"folder-icon",style:({ '--color-folder': props.row.projectColor })}),_c('span',[_vm._v(_vm._s(props.row.projectName))])]),_c('q-td',{key:"description",staticClass:"td-project-description",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.projectDescription)+" ")]),_c('q-td',{key:"status",staticClass:"td-status",attrs:{"props":props}},[_c('span',{class:['status', props.row.projectStatus]},[_vm._v(" "+_vm._s(_vm._f("translateStatus")(props.row.projectStatus))+" ")])]),_c('q-td',{key:"update",staticClass:"td-update",attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm._f("formattingDate")(props.row.updatedOn))+" ")]),_c('q-td',{key:"actions",staticClass:"td-actions",attrs:{"props":props}},[_c('q-btn',{staticClass:"btn-arrow",attrs:{"type":"button"}})],1)],1)]}}],null,false,160419935)})],1):_c('LoadingOrEmpty',{attrs:{"loading":_vm.isAllProjects ? _vm.getLoadingAllProjects : _vm.getLoadingLastProjects,"custom-empty":true},scopedSlots:_vm._u([{key:"customEmpty",fn:function(){return [(_vm.isAllProjects == false)?_c('div',{staticClass:"no-documents"},[_vm._v(" Aucun projet en cours ")]):_vm._e(),(_vm.isAllProjects == true)?_c('div',{staticClass:"no-documents"},[_vm._v(" Aucun projet crée ")]):_vm._e()]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }