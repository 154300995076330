<template>
  <div class="table-projects">
    <div class="top">
      <div class="title">{{ title }}</div>
      <div class="action">
        <q-btn
          class="btn-new"
          icon-right="add"
          label="Nouveau projet"
          @click="$router.push('/new-project')"
        />
      </div>
    </div>
    <div class="bottom">
      <div
        class="row"
        v-if="
          (isAllProjects && getAllProjects.length) ||
            (!isAllProjects && getLastProjects.length)
        "
      >
        <q-table
          :data="
            isAllProjects
              ? isSearch
                ? searchAll
                : getAllProjects
              : isSearch
              ? searchLast
              : getLastProjects
          "
          :columns="columns"
          :hide-bottom="true"
          :pagination.sync="pagination"
          :sort-method="customSort"
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              @click="
                $router.push({
                  name: 'Project',
                  params: { projectId: props.row.projectId }
                })
              "
            >
              <q-td
                key="name"
                :props="props"
                class="related-td td-project-name"
              >
                <span
                  class="folder-icon"
                  :style="{ '--color-folder': props.row.projectColor }"
                ></span>
                <span>{{ props.row.projectName }}</span>
              </q-td>
              <q-td
                key="description"
                :props="props"
                class="td-project-description"
              >
                {{ props.row.projectDescription }}
              </q-td>
              <q-td key="status" :props="props" class="td-status">
                <span :class="['status', props.row.projectStatus]">
                  {{ props.row.projectStatus | translateStatus }}
                </span>
              </q-td>
              <q-td key="update" :props="props" class="td-update">
                {{ props.row.updatedOn | formattingDate }}
              </q-td>
              <q-td key="actions" :props="props" class="td-actions">
                <q-btn class="btn-arrow" type="button"></q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <LoadingOrEmpty
        v-else
        :loading="
          isAllProjects ? getLoadingAllProjects : getLoadingLastProjects
        "
        :custom-empty="true"
      >
        <template v-slot:customEmpty>
          <div v-if="isAllProjects == false" class="no-documents">
            Aucun projet en cours
          </div>
          <div v-if="isAllProjects == true" class="no-documents">
            Aucun projet crée
          </div>
        </template>
      </LoadingOrEmpty>
    </div>
  </div>
</template>

<script>
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty.vue'
import { format, getTime } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TableProjects',
  components: {
    LoadingOrEmpty
  },
  props: {
    title: {
      type: String,
      default: 'Mes projets en cours'
    },
    isAllProjects: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchAll: [],
      isSearch: false,
      searchLast: [],
      columns: [
        {
          name: 'name',
          label: 'Nom du projet',
          ffield: row => row.projectName,
          sortable: true,
          align: 'left'
        },
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          sortable: true,
          align: 'left'
        },
        {
          name: 'status',
          label: 'Statut',
          field: 'status',
          sortable: true,
          align: 'left'
        },
        {
          name: 'update',
          label: 'Dernière màj.',
          field: 'update',
          sortable: true,
          align: 'left'
        },
        { name: 'actions', label: 'Actions', field: 'actions', align: 'left' }
      ],
      pagination: {
        rowsPerPage: 7
      }
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getLastProjects',
      'getLoadingLastProjects',
      'getErrorLastProjects',
      'getAllProjects',
      'getLoadingAllProjects',
      'getErrorAllProjects',
      'getSearch'
    ])
  },
  async created() {
    this.setSearch({ message: '' })
    this.isAllProjects
      ? await this.fetchAllProjects()
      : await this.fetchLastProjects()
    this.pagination.rowsPerPage = this.isAllProjects
      ? this.getAllProjects.length
      : this.getLastProjects.length
  },
  methods: {
    ...mapActions('projects', [
      'fetchAllProjects',
      'fetchLastProjects',
      'setSearch'
    ]),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    buildSearchLastMethod(message) {
      this.searchLast = this.getLastProjects.filter(item => {
        const updatedOn = item.updatedOn.replace(/\s/, 'T') || new Date()
        const date = format(new Date(updatedOn), 'dd/MM/yyyy')
        if (
          item.projectName.toLowerCase().indexOf(message) > -1 ||
          item.projectDescription.toLowerCase().indexOf(message) > -1 ||
          date.indexOf(message) > -1
        ) {
          return item
        }
      })
    },
    buildSearchAllMethod(message) {
      this.searchAll = this.getAllProjects.filter(item => {
        const updatedOn = item.updatedOn.replace(/\s/, 'T') || new Date()
        const date = format(new Date(updatedOn), 'dd/MM/yyyy')
        if (
          item.projectName.toLowerCase().indexOf(message) > -1 ||
          item.projectDescription.toLowerCase().indexOf(message) > -1 ||
          date.indexOf(message) > -1
        ) {
          return item
        }
      })
    },
    customSort(rows, sortBy, descending) {
      const data = [...rows]

      switch (sortBy) {
        case 'name':
          return this.handlerSort(data, descending, 'projectName')
        case 'description':
          return this.handlerSort(data, descending, 'projectDescription')
        case 'status':
          return this.handlerSort(data, descending, 'projectStatus')
        case 'update':
          return this.handlerSort(data, descending, 'updatedOn')
        default:
          return data
      }
    },
    handlerSort(data, descending, sortBy) {
      return data.sort((a, b) => {
        const x = descending ? b : a
        const y = descending ? a : b

        if (sortBy === 'updatedOn') {
          const xSortBy = x[sortBy].replace(/\s/, 'T') || new Date()
          const ySortBy = y[sortBy].replace(/\s/, 'T') || new Date()
          const xFormat = getTime(new Date(xSortBy))
          const yFormat = getTime(new Date(ySortBy))
          return xFormat > yFormat ? 1 : xFormat < yFormat ? -1 : 0
        }

        return x[sortBy].toLowerCase() > y[sortBy].toLowerCase()
          ? 1
          : x[sortBy].toLowerCase() < y[sortBy].toLowerCase()
          ? -1
          : 0
      })
    }
  },
  watch: {
    getErrorLastProjects: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorAllProjects: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getSearch: function(message) {
      if (message && message.length) {
        this.isSearch = true

        if (this.getLastProjects.length) {
          this.buildSearchLastMethod(message)
        }

        if (this.getAllProjects.length) {
          this.buildSearchAllMethod(message)
        }
      } else {
        this.isSearch = false
      }
    }
  },
  filters: {
    translateStatus: function(status) {
      if (status == 'ONGOING') {
        return 'En cours'
      } else {
        return 'Terminé'
      }
    }
  }
}
</script>
